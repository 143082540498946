import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from "gatsby";

import Layout from '../components/layout'
import { CallToAction, FAQ, FeaturedWineries, TextSection, WhatToExpect, WineryCount} from '../components/sections';
import { BackgroundSection } from '../components/primitives';

import './about.scss'


const AboutPage = ({data, location}) => {
    const {
        directus: {
            about_page
        }
    } = data;

    return(
        <Layout
            className='about-page'
            currentPage='about'
            title='About VIND - Explore Wineries Worldwide'
            location={location}
            description={about_page?.about_vind ?? ''}
            image={about_page?.featured_image ?? null}
        >
            <BackgroundSection
                className='section-hero section-hero-secondary'
                image={about_page?.featured_image ?? ''}
                overlayColor='rgba(0,0,0,.40)'
                isGatsbyImage
            />

            <TextSection title='About Us' content={about_page?.about_vind ?? ''} className='about-us-section'/>

            <BackgroundSection
                className='section-hero'
                image={about_page?.about_vind_image ?? ''}
            />

            <FAQ faqQuestions={about_page?.faqs ?? []} />

            <WhatToExpect />

            <WineryCount
                intro={about_page?.intro}
            />

            <FeaturedWineries/>

            <CallToAction
                bgImage={about_page?.call_to_action_image ?? ''}
                ctaText={about_page?.call_to_action_text}
            />
        </Layout>
    )
}

AboutPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}

AboutPage.defaultProps = {
}

export const AboutPageQuery = graphql`
  query {
    directus {
        about_page {
          about_vind
          about_vind_image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          call_to_action_text
          call_to_action_image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          intro
          faqs
          featured_image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
      }
    }
  }
`

export default AboutPage